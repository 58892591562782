import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import { API_URL, LOCAL_MAYA_PAYMENT_API_URL, SUICA_SERVER } from '../common/api';
import {dbPostResp } from '../model/dbPostResp';
import { catchError } from 'rxjs/operators';

import {LOCAL_PAYMENT_API_URL,LOCAL_PAYMENT_PORT } from '../common/api';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  //ipaddr : string = "http://192.168.10.11";
  ipaddr : string = LOCAL_PAYMENT_API_URL;
  
  server : string  = this.ipaddr + ":"+LOCAL_PAYMENT_PORT+"/";
  suica_server : string  = this.ipaddr + ":"+SUICA_SERVER+"/";

  constructor(private http: HttpClient) {
  }


  //// MENU
  menu_items(){
    return this.http.get(this.server + 'active_menu_items', {withCredentials: true});
  }
  menu_addons(){
    return this.http.get(this.server + 'active_menu_addons', {withCredentials: true});
  }

  telemetry2(): Observable<any> {
    console.log(" telemetry2 invoked")
    return this.http.get(this.suica_server + 'telemetry', {withCredentials: true});
    /*
    return this.http.get<any>(this.suica_server + 'telemetry', {withCredentials: true})
      .pipe(
        catchError(this.handleError)
      );
    */
  }
 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error      
      errorMessage = "{E1 : "+error.message+"} ";
      console.log("E 11 ",error);
    } else {
      // server-side error
      errorMessage = "{E2 : "+error.message+"} ";
      console.log("E 22 ",error);
    }
    return throwError(errorMessage);
  }

  telemetry(){
    //return this.http.get(this.suica_server + 'telemetry', {withCredentials: true});

    try{
      
      return this.http.get(this.suica_server + 'telemetry', {withCredentials: true});                
    }
    catch(err) {
        console.log(" ERROR CAUGHT  ", err);
    }

  }


  suicarecords(){
    return this.http.get(this.suica_server + 'suicarecords', {withCredentials: true});
  }

  balance_check(){
    return this.http.get(this.suica_server + 'BalanceInquiry', {withCredentials: true});
  }
  
  //// PLACING OF ORDER
  new_order( product_id: string, created_by: string, ice: string, milk: string, gig_command: string, cup_type: string, payid: string ): Observable<dbPostResp>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    return this.http.post<dbPostResp>(this.server + '/new_order',{ 
      product_id : product_id, 
      gig_command: gig_command, 
      ice: ice, 
      milk: milk, 
      created_by : created_by,
      cup_type: cup_type,
      payid: payid
    }, httpOptions);
  }
  new_paid_order( product_id: string, created_by: string, payment_id: string ): Observable<dbPostResp>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    return this.http.post<dbPostResp>(this.server + '/new_order',{ product_id : product_id, created_by : created_by, payment_id : payment_id }, httpOptions);
  }

  new_multi_order( orders, customername, paymentid, subtotal): Observable<dbPostResp>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      })
    };
    return this.http.post<dbPostResp>(this.server + '/new_multi_order',{ 
      orders : orders,
      customername: customername,
      paymentid: paymentid,
      subtotal: subtotal
    }, httpOptions);
  }
 
  //// PAYMENT APIs
  payment_server : string;
  
  wait_for_suica_payment(subtotal_in_dollar: number, id: string ) {
    console.log("yen charged: "+ (subtotal_in_dollar).toString() );
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', LOCAL_PAYMENT_API_URL+':'+LOCAL_PAYMENT_PORT);
    
    console.log("ID :"+id );

    
    let response=  this.http.post(
      this.suica_server + 'SuicaPaymentRequest?amount='+(subtotal_in_dollar).toString()+'&Terminalid=T'+id, 
      {},
      { headers, responseType: 'json'}
    );
     
    console.log(" wait_for_suica_payment RESPONSE: ",response);

    return response;

  }
  
  refund_last_order( ) {
    console.log("refund suica payment");
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', LOCAL_PAYMENT_API_URL+':'+SUICA_SERVER);

    return this.http.post(
      this.suica_server + '/RefundLastOrder', 
      {},
      { headers, responseType: 'json'}
    );
  }


  wait_for_payment(order_item_id: string, subtotal_in_dollar: number, id: string,TransactionID: string ) {
    // demux kiosk id=1 to port 3101, id=2 to port 3201 etc...
    // diff gateway executables will listen on these ports
    this.payment_server = LOCAL_PAYMENT_API_URL+":"+LOCAL_PAYMENT_PORT;
    
   
    // console.log("dollars charged via bytestation: "+ (subtotal_in_dollar).toString() );
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', this.payment_server);

    // let payment_api_old = this.payment_server + '/api/Adyen/PaymentRequest?amount='+(subtotal_in_dollar).toString()+'&Terminalid=T'+id+'&ServiceID='+order_item_id+'&TransactionID='+TransactionID;
    let payment_api = this.payment_server + '/paymentrequest/'+(subtotal_in_dollar).toString()+'/T'+id +'/'+order_item_id+'/'+TransactionID
    // console.log("API TO CALL : ",payment_api);
    // let payment_api =   'https://crownfooddelivery.trigma.in/web/v1/orderinfo/testpay?amount='+(subtotal_in_dollar).toString()+'&Terminalid=T'+id+'&ServiceID='+order_item_id;
    return this.http.post(
      payment_api, 
      {},
      { headers, responseType: 'text'}
    );
  }



  wait_for_ez_payment(order_item_id: string, subtotal_in_dollar: number, id: string ) {
    // demux kiosk id=1 to port 3101, id=2 to port 3201 etc...
    // diff gateway executables will listen on these ports
    this.payment_server = "http://192.168.10.11:81";//"http://192.168.10.11:81";
    
    //http://192.168.10.11:64807/api/Adyen/PaymentEZRequest?amount=1&EZTerminalid=t3&ServiceID=23009009

    if ( (id=='1') || (id=='2') )
    {
      if (id == '1') id = '3';
      if (id == '2') id = '4';
    }

    var subtotal_in_cents = subtotal_in_dollar * 100

    console.log("cents charged: "+ (subtotal_in_cents).toString() );
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', 'http://192.168.10.11:81');

    return this.http.post(
      this.payment_server + '/api/Adyen/PaymentEZRequest?amount='+(subtotal_in_cents).toString()+'&EZTerminalid=T'+id+'&ServiceID='+order_item_id, 
      {},
      { headers, responseType: 'text'}
    );
  }
  
  wait_for_abort_payment(order_item_id: string, id: string ) {
    this.payment_server = LOCAL_PAYMENT_API_URL+":"+LOCAL_PAYMENT_PORT;

    const headers = new HttpHeaders();
    
    headers.append('Access-Control-Allow-Origin', this.payment_server);
    
    // let API_URL_old = this.payment_server + '/api/Adyen/AbortPaymentRequest?ServiceID='+order_item_id+'&Terminalid=T'+id;
    let API_URL = this.payment_server + '/abortpayment/T'+id+'/'+order_item_id;
    // console.log(" wait_for_abort_payment API URL  ",API_URL)
    return this.http.post(
      API_URL, 
      {}, 
      { headers, responseType: 'text'}
    );
  }

  
  submit_payment_request(product_id: string): Observable<dbPostResp>{
    return this.http.post<dbPostResp>(this.server + '/submit_payment_request', {
      product_id: product_id
    }, {withCredentials: true});
  }

  check_payment_successful(payment_id: string){
    return this.http.get(this.server + '/check_payment_successful?payment_id=' + payment_id, {withCredentials: true});
  }


  //// PRINTING

  // ELO's local address to pointer to local print daemon('node printd.js')
  // 192.168.10.31 for id=1, 32 for id=2 ....
  elocal_ip = 'localhost';
  //elocal_ip = '192.168.10.3';

  // for all drink receipts to local USB printer
  print_receipt(cartridge: string, order_item_id: string, product_names: string, product_prices: string, id: string, custname: string, deducted: string, balance: string, card_idi: string): Observable<dbPostResp>{
    if (id == '3') id = '1';
    if (id == '4') id = '2';
    if (id == '5') id = '1';
    if (id == '6') id = '2';
    return this.http.post<dbPostResp>('http://' + this.elocal_ip + ((this.elocal_ip != 'localhost') ? id : '')  + ':4000/print_receipt', {
      cartridge: cartridge,
      order_item_id: order_item_id,
      product_names: product_names,
      product_prices: product_prices,
      custname: custname,
      deducted: deducted,
      balance: balance,
      card_idi: card_idi
    }, {withCredentials: true});
  }
  fetch_receipt_details(payment_id: string, status: string){
    if (status == 'PAID'){
      return this.http.get(this.server + '/fetch_receipt_details?payment_id=' + payment_id, {withCredentials: true});
    } else {
      return this.http.get(this.server + '/fetch_receipt_details_error?payment_id=' + payment_id, {withCredentials: true});
    }
  }

  reprint_receipt(cartridge: string, order_item_id:string, custname:string, total_price: string, id: string, deducted: string, balance: string, card_idi: string, status: string): Observable<dbPostResp>{
    if (status == 'PAID'){
      return this.http.post<dbPostResp>('http://' + this.elocal_ip + ((this.elocal_ip != 'localhost') ? id : '')  + ':4000/reprint_receipt', {
        cartridge: cartridge,
        order_item_id: order_item_id,
        total_price: total_price,
        custname: custname,
        deducted: deducted,
        balance: balance,
        card_idi: card_idi,
        status_to_print: ""
      }, {withCredentials: true});
    } else {
      return this.http.post<dbPostResp>('http://' + this.elocal_ip + ((this.elocal_ip != 'localhost') ? id : '')  + ':4000/reprint_receipt', {
        cartridge: cartridge,
        order_item_id: "",
        total_price: total_price,
        custname: custname,
        deducted: deducted,
        balance: balance,
        card_idi: card_idi,
        status_to_print:  {
          'ERROR_NEGA': "Card Invalid / NEGA",
          'ERROR_INSUFFICIENT' : "Insufficient Balance / 残高不足",
          'REFUNDED': "Successful Refund / 返金",
          'CHECKED': "Check Balance",
          'CHECKED_NEGA': "Check Balance/ NEGA",
          'ERROR': "General Error"}[status]
      }, {withCredentials: true});
    }
  }
  print_test_prize(cartridge: string, coupon: string, id: string): Observable<dbPostResp>{
    return this.http.post<dbPostResp>('http://' + this.elocal_ip  + ((this.elocal_ip != 'localhost') ? id : '')  + ':4000/print_coupon', {
      cartridge: cartridge,
      coupon: coupon
    }, {withCredentials: true});
  }


  printPdf(url){
    try{
      
      return this.http.get(url, {withCredentials: true});                
    }
    catch(err) {
        console.log(" PRINT ERROR CAUGHT  ", err);
    }

  }

  wait_for_Test_payment(order_item_id: string, subtotal_in_dollar: number, id: string, status:string, order_info_uuid:string, TransactionID:string ) {

    this.payment_server = LOCAL_PAYMENT_API_URL+":"+LOCAL_PAYMENT_PORT;
    
    const headers = new HttpHeaders();
    headers.append('Access-Control-Allow-Origin', this.payment_server);

    // let payment_api = this.payment_server + '/api/Adyen/PaymentRequest?amount='+(subtotal_in_dollar).toString()+'&Terminalid=T'+id+'&ServiceID='+order_item_id;
   
    let payment_api =   API_URL + 'orderinfo/testpaybytes?amount='+(subtotal_in_dollar).toString()+'&Terminalid=T'+id+'&ServiceID='+order_item_id+'&status='+status+'&order_info_uuid='+order_info_uuid+'&TransactionID='+TransactionID;
    // console.log("API TO CALL : ",payment_api);
    return this.http.post(
      payment_api, 
      {},
      { headers, responseType: 'text'}
    );
  }

  mayaPaymentProcess(orderAmonut, refNo: string, reference_number: string): Observable<any> {
    const payload =   {
      price: orderAmonut,
      merchantRefNo: refNo,
      orderNo: reference_number
    }
    let mayaApiUrl = LOCAL_MAYA_PAYMENT_API_URL + '/paymentrequest'
    const headers = new HttpHeaders();
    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    headers.append('Access-Control-Allow-Origin', LOCAL_MAYA_PAYMENT_API_URL);
    return this.http.post(mayaApiUrl, payload, options);
  }

  mayaPaymentProcessQR(orderAmonut, refNo: string, reference_number: string): Observable<any> {
    const payload =   {
      price: orderAmonut,
      merchantRefNo: refNo,
      orderNo: reference_number,
      customTenderRequestField: "qr:qrph;"
    }
    let mayaApiUrl = LOCAL_MAYA_PAYMENT_API_URL + '/paymentrequest'
    const headers = new HttpHeaders();
    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    headers.append('Access-Control-Allow-Origin', LOCAL_MAYA_PAYMENT_API_URL);
    return this.http.post(mayaApiUrl, payload, options);
  }

  mayaPaymentCheckStatus(): Observable<any> {
    let mayaApiUrl = LOCAL_MAYA_PAYMENT_API_URL + '/checkpaymentstatus'
    const headers = new HttpHeaders();
    let options: any = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    }
    headers.append('Access-Control-Allow-Origin', LOCAL_MAYA_PAYMENT_API_URL);
    return this.http.get(mayaApiUrl, options);
  }

  
}

